import React from 'react';
import { graphql, navigate } from 'gatsby';
import { Row, Col, Container, Nav, CloseButton } from 'react-bootstrap';

import Layout from '@layouts/layout';
import SEO from '@components/seo';
import BannerContainer from '@components/bannerContainer';

const RefundPage = () => {
  return (
    <Layout
      headerProps={{
        disableLogin: true,
        NavigationOptions: (
          <Nav.Link onClick={() => navigate(-1)}>
            <CloseButton />
          </Nav.Link>
        ),
      }}
    >
      <SEO title="Política de reembolso y devoluciones" />

      <Container>
        <BannerContainer title="Política de reembolso y devoluciones" />

        <Row>
          <Col xs="12" md="10" lg="8" className="mx-auto py-4">
            <p>
              Solamente podrá ser devuelto el pago de una orden que no pudo ser
              completada debido a que el teléfono de destino no exista, o esté
              deshabilitado, y/o el proveedor local confirme que no pudo
              acreditar el saldo.
            </p>
            <p>
              Si el cliente califica para un reembolso, una vez que haya sido
              procesado del lado de aMéxico, puede demorar hasta 30 días
              hábiles en aparecer reflejado en el registro de la tarjeta
              bancaria utilizada, dependiendo de la operación de las entidades
              financieras involucradas en la transacción.
            </p>
            <p>
              Si la orden fue procesada exitosamente con la información provista
              por el cliente, la misma no califica para un reembolso, siendo el
              cliente el máximo responsable por cualquier error en el número de
              teléfono de destino. En caso de que no pueda ser completada una
              orden y antes de emitirse el reembolso, el cliente puede
              comunicarse con el Servicio de Atención al Cliente de aMéxico
              por medio del teléfono o el correo electrónico e indicar otro
              teléfono/cuenta al que enviar el saldo correspondiente por el pago
              realizado.
            </p>
            <p>
              <b>
                Al utilizar nuestros servicios y aceptar nuestros Términos y
                Condiciones, usted acepta esta Política de Reembolso y
                Devoluciones.
              </b>
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default RefundPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
